/*this code below is the css of tables on list all question page*/
#table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
/*this code above is the css of tables on list all question page, etc*/


/*this code below is the css of forms in add new question page, etc*/
.questionform {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.question_form_submit_button{
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 7% 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.question_form_submit_button:hover{
  width: 100%;
  background-color: rgb(76, 160, 175);
  color: white;
  padding: 14px 20px;
 
}





.question_form_div{
  border-radius: 5px;
  background-color: #b6c5d3;
  padding: 8%;
  margin: 8%;
}

/*this code below is the css of forms in add new question page, etc*/



.center_div{
      width:400px;
      margin: 5% auto;

}
